import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Link,
  Divider,
  Stack,
  Container,
  Button,
  Tooltip,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FaArtstation, FaInstagram } from "react-icons/fa6";
import { FaCoffee } from "react-icons/fa";
import { GrMailOption } from "react-icons/gr";
import { IconContext } from "react-icons/lib";
import { PiBehanceLogoBold } from "react-icons/pi";
import { LuMail } from "react-icons/lu";
import birb from "./imgs/birb.png";
// GrMailOption

function App() {
  const bg = "#a9def9";
  const fontColor = "#244878";

  const [imageLoaded, setImageLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  function linkStuffIdk(icon, text, link, color = "red") {
    return (
      <Box>
        <Tooltip title={text} placement="top">
          <Link target="_top" href={link} rel="noreferrer" underline="none">
            <IconContext.Provider
              value={{
                color: color,
                className: "global-class-name",
                size: "2em",
                style: { verticalAlign: "middle" },
              }}
            >
              {icon}
            </IconContext.Provider>
          </Link>
        </Tooltip>
      </Box>
    );
  }
  function displayLogo() {
    return (
      <Box
        component="img"
        sx={{ width: isMobile ? "80vw" : "25vw" }}
        alt="The house from the offer."
        onLoad={() => setImageLoaded(true)}
        src={birb}
      />
    );
  }

  function displayCommissions() {
    return (
      <>
        <Typography
          display={"inline"}
          sx={{
            fontFamily: "Madimi One",
            fontWeight: 400,
            color: fontColor,
            fontSize: "1.75em",
          }}
        >
          Commissions:{" "}
        </Typography>

        <Link
          target="_top"
          href={"//ko-fi.com/yelobirb"}
          rel="noreferrer"
          underline="none"
        >
          <Typography
            sx={{
              fontFamily: "Madimi One",
              fontWeight: 400,
              fontSize: "1.75em",
            }}
            display={"inline"}
            color={"#127A00"}
          >
            Open{" "}
          </Typography>
        </Link>
      </>
    );
  }

  function displayInfo() {
    return (
      <Paper sx={{ p: 3, borderRadius: "16px" }}>
        <Typography
          style={{
            textAlign: "center",
            fontFamily: "Madimi One",
            color: fontColor,
            fontWeight: 700,
            fontSize: "3.75em",
          }}
          variant="h3"
        >
          Yelobirb
        </Typography>

        <Box
          sx={{
            mb: 2,
            textAlign: "center",
            fontSize: "2em",
            color: fontColor,
          }}
        >
          Rachel Zuehlke
        </Box>
        <Divider />
        {displayCommissions()}
        <Stack direction={"row"} sx={{ mt: 2 }} spacing={2}>
          {linkStuffIdk(
            <FaInstagram />,
            "Yelobirb",
            "//www.instagram.com/yelobirb/?hl=en",
            "#e4c1f9"
          )}
          {linkStuffIdk(
            <FaCoffee />,
            "Yelobirb",
            "//ko-fi.com/yelobirb",
            "#B6465F"
          )}
          {linkStuffIdk(
            <PiBehanceLogoBold />,
            "Yelobirb",
            "//www.behance.net/yelobirb?locale=en_US",
            "#B7C0EE"
          )}
          {linkStuffIdk(
            <FaArtstation />,
            "Yelobirb",
            "//www.artstation.com/yelobirb",
            "#F5B7C2"
          )}
          {linkStuffIdk(
            <LuMail />,
            "yelobirb.art@gmail.com",
            "mailto:yelobirb.art@gmail.com",
            "#d0f4de"
          )}
        </Stack>
      </Paper>
    );
  }

  return (
    <Box
      bgcolor={bg}
      height={"100vh"}
      display="flex"
      sx={{ fontFamily: "Madimi One", fontWeight: 400 }}
    >
      <Container>
        <Stack
          container
          spacing={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          {displayLogo()}

          {imageLoaded && displayInfo()}
          <Link
            target="_top"
            href={"//birb.rsvp"}
            rel="noreferrer"
            underline="none"
          >
            <Box sx={{ fontSize: 22 }}>Continue to Site</Box>
          </Link>
        </Stack>
      </Container>
    </Box>
  );
}

export default App;
